import { Component, OnInit } from '@angular/core';
import { TopAnswerer,SideContentService } from '../../../core';

@Component({
  selector: 'app-top-answers',
  templateUrl: './top-answers.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
export class TopAnswersComponent implements OnInit {

constructor(private sidecontentservice:SideContentService) { }
topanswerer: TopAnswerer;
ngOnInit(): void {
  this.getTopAnswer();
}

getTopAnswer(){
  this.sidecontentservice.topAnswer()
    .subscribe(data => {      
      this.topanswerer = data;
  });
}
}