import { Component, OnInit , Input, EventEmitter, Output, Injectable} from '@angular/core';
import { Router,ActivatedRoute , RouterState } from '@angular/router';
import { Article,UserService } from '../../../core';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-article-preview',
  templateUrl: './article-preview.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
@Injectable()
export class ArticlePreviewComponent implements OnInit {
  @Input() article: Article;
  @Output() onLikedislike: EventEmitter<any> = new EventEmitter<any>();
  uservote:any;
  state: RouterState = this.router.routerState;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private titleService:Title 
  ) {    
    
    
  }

  isAuthenticated: boolean;

  ngOnInit(): void {
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
      }
    );
  }

  navQuestDetail(topicId){  
    
   
    if (!this.isAuthenticated) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: '/article/'+topicId }});
     // this.router.navigate(['/login']);
      return;
    }else{
      this.router.navigate(['/article', topicId]);
    }

  }
  doTopicVote(topicid, vote){
    if (!this.isAuthenticated) {
      this.router.navigateByUrl('/login');
      return;
    }else{
      const data = [topicid, vote];
      this.onLikedislike.emit(data);
    }
  }

}
