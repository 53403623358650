<div class="left-section-content">
<div class="left-section-box top-answerers">
    <h3 class="section-title"><i class="fa fa-star"></i>Top answerers</h3>
    <ul class="top-answerer-list">
      <li *ngFor="let i of topanswerer">
        <div class="d-flex">
          <div class="profile-image">
            <img src="api/assets/images/avatars/{{i.author.Image}}" title="" alt="">
          </div>
          <div class="profile-description">
            <p>{{i.author.Name}} </p>
            <span>{{i.NoOfAnswers}} Answers Master</span>
          </div>
        </div>
      </li>
    </ul>
    <!-- <p class="text-right"><a href="#" class="view-more">5311 Answers Master <i
          class="fa fa-long-arrow-right"></i></a></p> -->
  </div>
  </div>