import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ModuleWithProviders, NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { AuthModule } from './auth/auth.module';
import { FooterComponent, HeaderComponent,  SharedModule,  } from './shared';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { UserProfileComponent } from './user/user-profile.component';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
//import { AutofocusDirective } from './core/directives/autofocus.directive';


@NgModule({
  declarations: [
    AppComponent, 
    FooterComponent, 
    HeaderComponent, UserProfileComponent,
  ],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,      
    CoreModule,  
    SharedModule,
    HomeModule,
    AuthModule,
    AppRoutingModule,
    NgbModule,
    JwSocialButtonsModule   
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
