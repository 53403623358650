<div class="left-section-content"  id="topcategories">
<div class="left-section-box star-of-day">
    <h3 class="section-title"><i class="fa fa-star"></i>Star of the day</h3>
    <div class="d-flex">
      <div class="profile-image">
        <img src="api/assets/images/avatars/{{staruser.author.Image}}" title="" alt="">
      </div>
      <div class="profile-description">
        <p>{{staruser.author.Name}}</p>
        <!--<span>{{staruser.NoOfAnswers}} Answers Master</span>-->
      </div>
    </div>
  </div>
  </div>
