<section class="main-content signup-bg">
  <div class="container">
    <div class="massages" *ngIf="showMassage">
      <p class="opt-text sucess-text alert alert-success"><i class="fa fa-check-circle"></i> {{successMassage}}</p>
    </div>
    <div class="row">
      <!-- <div class="col-md-12 col-lg-7 col-12 signup-left">
        <div class="business-img">
          <img src="../../../assets/images/design-people.jpg" title="" alt="" />
        </div>
      </div> -->
      <div class="col-md-12 col-lg-12 col-12 signup-right">
        <div class="form-signup">
          <div class="text-center mb-3 mt-3">
            <img src="../../../assets/images/IFLogo3V5.png" alt="" width="200" height="56">
          </div>
          <button type="click" (click)="socialSignIn('google')" class="btn btn-google">Sign up with Google </button>
          <button type="click" (click)="socialSignIn('facebook')" class="btn btn-facebook">Sign up with Facebook
          </button>
         <!-- <button type="click" (click)="socialSignIn('linkedin')" class="btn btn-linkedin">Sign up with Linkedin
          </button>-->
          <div >
            <!-- *ngIf="signUpForm" -->
            <form [formGroup]="signUpform" (ngSubmit)="registerUser()">
              <div class="form-label-group">
                <input type="text" id="firstname" class="form-control" placeholder=""
                  formControlName="firstname" autocomplete="off" appAutofocus>
                <label for="firstname">Your name</label>
                <div *ngIf="signUpform.get('firstname').invalid && (signUpform.get('firstname').dirty || signUpform.get('firstname').touched)"
                  class="alert alert-danger">
                  <div *ngIf="signUpform.get('firstname').errors.required">
                    Name is required.
                  </div>
                </div>
              </div>
              <div class="form-label-group">
                <input type="email" id="email" class="form-control" placeholder="" formControlName="email"
                  autocomplete="off">
                <label for="email">Email</label>
                <div *ngIf="signUpform.get('email').invalid && (signUpform.get('email').dirty || signUpform.get('email').touched)"
                  class="alert alert-danger">
                  <div *ngIf="signUpform.get('email').errors.required">
                    Email is required.
                  </div>
                  <div *ngIf="signUpform.get('email').errors.email">Email must be a valid email address</div>
                </div>
                <div *ngIf="emailError" class="alert alert-danger">
                  <div>Email address is already exists.</div>
                </div>
              </div>
              <div class="form-label-group">
                <input type="password" id="password" class="form-control" placeholder=""
                  formControlName="password" autocomplete="off">
                <label for="password">Password</label>
                <div
                  *ngIf="signUpform.get('password').invalid && (signUpform.get('password').dirty || signUpform.get('password').touched)"
                  class="alert alert-danger">
                  <div *ngIf="signUpform.get('password').errors.required">
                    Password is required.
                  </div>
                  <div *ngIf="signUpform.get('password').errors.minlength">
                    Password must be at least 6 characters.
                  </div>
                </div>
              </div>
              <!-- <div class="checkbox mb-3">
                <label>
                  <input type="checkbox" value="remember-me"> Remember me
                </label>
              </div> -->
              <button class="btn btn-lg btn-warning btn-block" type="submit" [disabled]="!signUpform.valid">Sign up</button>
              <div>
                <ul class="social-link">
                  <li>Already have an account? <a routerLink="/login" class="mr-1">Log in</a>
                    <!-- |<a href="#" class="ml-1">Forgot password</a> -->
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
