
<div class="left-section-content"  id="topanswerers">
  <div class="left-section-box top-categories">
    <h3 class="categories-title"><i class="fa fa-bars" aria-hidden="true"></i>Top
      categories</h3>
    <div id="accordion" class="categories-accordion">
      <ngb-accordion [closeOthers]="true" activeIds="static-1" *ngFor="let i of topcategories">
        <ngb-panel id="static-2" class="card">
          <ng-template ngbPanelTitle class="card-header">
            <a class="card-link" data-toggle="collapse" href="#collapseOne">
              {{i.CategoryTitle}}
            </a>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="card-body">
              <ul>
                <li *ngFor="let j of i.subCategory" (click)="searchCat(j.CategoryTitle)"><a href="javascript:void(0">{{j.CategoryTitle}}</a></li>
              </ul>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</div>
