<section class="main-content login-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-7 col-12 signin-left">
        <div class="business-img">
          <img src="../../../assets/images/design-people.jpg" title="" alt="" />
        </div>
      </div>
      <div class="col-md-12 col-lg-5 col-12 signin-right">
        <div class="form-loginForm form-signUp-bg">
          <div class="text-center   mb-3 mt-3">
            <img src="assets/images/IFLogo3V5.png" alt="" width="200" height="56">
          </div>
          <button type="click" (click)="socialSignIn('google')" class="btn btn-google">Log in with Google </button>
          <button type="click" (click)="socialSignIn('facebook')" class="btn btn-facebook">Log in with Facebook
          </button>
          <!-- <button type="click" (click)="linkedlogin()" class="btn btn-linkedin">Log in with Linkedin
        </button> -->
          <form [formGroup]="authForm" (ngSubmit)="submitForm()">
            <div class="form-label-group">
              <input type="email" id="username" class="form-control" formControlName="email" required="" placeholder=""
                appAutofocus>
              <label for="username">Email</label>
              <div
                *ngIf="authForm.get('email').invalid && (authForm.get('email').dirty || authForm.get('email').touched)"
                class="alert alert-danger">
                <div *ngIf="authForm.get('email').errors.required">
                  Email is required.
                </div>
              </div>
            </div>
            <div class="form-label-group">
              <input type="password" formControlName="password" id="password" class="form-control" required=""
                placeholder="">
              <label for="password">Password</label>
              <div
                *ngIf="authForm.get('password').invalid && (authForm.get('password').dirty || authForm.get('password').touched)"
                class="alert alert-danger">
                <div *ngIf="authForm.get('password').errors.required">
                  Password is required.
                </div>
              </div>
              <div class="alert alert-danger" *ngIf="showMassage">
                <p>{{ responseMassage }}</p>
              </div>
            </div>
            <!-- <div class="checkbox mb-3">
              <label>
                <input type="checkbox" value="remember-me"> Remember me
              </label>
            </div> -->
            <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!authForm.valid"
              type="submit">Log
              in</button>
            <!-- <button class="btn btn-lg btn-warning btn-block" type="submit">Sign Up</button> -->
            <div>
              <ul class="social-link">
                <li>Don’t have an account? <a routerLink="/register" class="mr-1">Sign up</a>|<a routerLink="/forgot-password" class="ml-1">Forgot password</a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
