import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SideContentService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,) { }

  trendingTopic() {
    return this.apiService.get("/topic/getTrending")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }
  
  topCategories() {
    return this.apiService.get("/topic/getTopCategories")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  starOfDay(){
    return this.apiService.get("/User/getStarOfDay")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  topAnswer(){
    return this.apiService.get("/User/getTopAnswerer")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }
}
