<section class="container">
  <div class="site-content">
    <div class="row">
      <div class="col-12">
        <div class="home-page-banner">
          <!-- <h1 class="homepage-tagline">Hello, what can we help you find?</h1>
         <h2 class="homepage-subtagline">If you have any question you can ask below or enter what you are
                        looking for!</h2> -->
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 md-lg-8">
              <div class="search-continer">
                <form class="form-inline search-form"  [formGroup]="searchForm" (ngSubmit)="submitForm()">
                  <input class="form-control mr-sm-2" type="search" formControlName="searchBox" placeholder="Search" aria-label="Search">
                  <button class="btn btn-outline-success my-2 my-sm-0 search-button" type="submit">Search</button>
                  <p class="serach-radio"><label><input type="radio" formControlName="searchType" name="searchType" value="0">Topic in
                      Discussion</label> <label><input type="radio" formControlName="searchType" name="searchType" value="1">Category</label>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!--Left Section Start-->
      <div class="col-12 col-md-12 col-lg-4 left-section">
        <div class="row">
          <div class="col-12">
            <div class="left-section-content">
              <!--Powerd by section-->
              <div class="left-section-box top-categories" id="staroftheday">
                <h3 class="categories-title">Powered by</h3>
                <div class="d-flex justify-content-center">
                  <a href="https://www.integrativesystems.com/" target="_blank"><img src="../../../assets/images/logo.png" alt="Integrative Systems Logo"></a> 
                </div>
               
              </div>
              <!--1st Section start-->              
              <app-star-of-day></app-star-of-day>
              <!--2ns Section categories start-->
              <app-categories></app-categories>
              <!--3rd Section Top answerers start-->              
              <app-top-answers></app-top-answers>             
              <!--4th Trending Topics start-->
              <app-treding-topics></app-treding-topics>             
            </div>
          </div>
        </div>

      </div>
      <!--Right section start-->
      <div class="col-12 col-md-12 col-lg-8 right-section">
        <!--Filter box start here-->
      
        <!--Filter box end here-->
        <!--Question box statr here-->
        <app-article-list (askQuestP)="askQuestP()"></app-article-list>
        <!--Question box end here-->
       
      </div>
    </div>
  </div>
</section>

<!-- Ask Question model start here -->
<div class="overlay" *ngIf="display"></div>
<div class="model ask-question-model" *ngIf="display">
  <div class="row justify-content-center">
    <div class="col-md-8 col-12">
      <div class="model-container">
        <div class="close-button" (click)="display=false"><i class="fa fa-times-circle"></i></div>
        <app-editor></app-editor>
      </div>
    </div>
  </div>
</div>
