import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {
@Input() public autoFocus : boolean;

public constructor(public el: ElementRef) {
 }

ngAfterContentInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 200)
  }

}
