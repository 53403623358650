import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Title } from "@angular/platform-browser";

import { Errors, UserService } from '../core';

import { User } from '../core/models/user.model';
import { Router } from '@angular/router';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  errors: Errors = {errors: {}};
  currentUser: User;
  currentUserNickName: User;
  active = 'top';
  changePasswordFrom : FormGroup;
  showMassage = false;
  sohwError = false;
  display = false;
  displaynick = false;
  displayUser = true;
  displayNickName=true;
  displayCompany = false;displayUserCompany =true;
  displayCompanyName = true;
  displayMobileNum = false;
  displayMobile = true;;
  saveButtons = false;
  cancelButtons = false;
  currentName;
  currentNickName;
  currentCompanyName;
  currentMobileNum;
  sohwUserError = false;
  allowSaveUserName;
  selectedFile:File=null;

  constructor(
    private userService : UserService,
    private fb : FormBuilder,
    private router: Router,
    private titleService:Title,
  ) { 

    //To set page title
    this.titleService.setTitle("User profile - IBMi Community");

    this.changePasswordFrom = this.fb.group({
      'currentpassword': ['', Validators.required],
      'newpassword': ['', Validators.required],
      'confirmpassword': ['', Validators.required]
    }, { validator: this.MustMatch("newpassword", "confirmpassword")}
    )
  }

  ngOnInit(): void {

    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        console.log(this.currentUser);
      }
    );
  }

  //For checking new password and confirm password validation
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  //For Change password
  changePassword(){
    this.userService.changePassword(this.changePasswordFrom.value).subscribe(
      data => {
        if(data.req_status == "0"){
          this.showMassage = false;
          this.sohwError = true;
        }else {
          this.sohwError = false;
          this.showMassage = true;
          setTimeout(() => {
            this.showMassage = false;
            this.router.navigateByUrl('/');
          }, 3000)
        }
      },
      error => {
        this.errors = error;
      }
    )
  }

  update(){
    
      this.currentUser.displayName = this.currentNickName;
      this.currentUser.companyName = this.currentCompanyName;
      this.currentUser.mobile = this.currentMobileNum;
    this.userService.userUpdate(this.currentNickName,this.currentCompanyName,this.currentMobileNum).subscribe(
      data => {
        this.ngOnInit();
        console.log(this.currentNickName)
      },
    );
  }


  // saveUsername(){
  //   this.currentUser.username = this.currentName;
  // }

  editCompanyname(){
    this.currentCompanyName = this.currentUser.companyName;
  }
  onFileSelected(event){  
    this.selectedFile=<File>event.target.files[0];
    this.userService.postSelectedFile(this.selectedFile)
      .subscribe(data => {
        this.userService.populate();
        //this.ngOnInit();
    });
    
  }
  
  

  editNickname(){    
    this.currentNickName = this.currentUser.displayName;

  }
  editMobile(){    
    this.currentMobileNum = this.currentUser.mobile;

  }
  // saveNickname(){
  //   this.currentUser.displayName = this.currentNickName;
  // }
  // saveCompanyname(){
  //   this.currentUser.displayName = this.currentCompanyName;
  // }



}
