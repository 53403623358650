import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService } from '../../core';
import { Title } from "@angular/platform-browser";

import { Socialusers } from '../../core/models/social-user.model';
import { GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider, AuthService } from 'angular-6-social-login';
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login';
import { SocialloginService } from '../../core/services/sociallogin.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signUpform :FormGroup;
  signUpForm = true;
  timeLeft: number = 300;
  interval;
  errors: Errors = {errors: {}};
  userEmail;
  emailError = false;
  successMassage:string;
  showMassage = false;
  showOtpForm = false;
  socialusers = new Socialusers();
  response : any;

  constructor(
    private fb: FormBuilder,
    private formotp : FormBuilder,
    private router: Router,
    private userService: UserService,
    private socialAuthService: AuthService,
    private socialloginService: SocialloginService,
    private titleService : Title,
  ) {

    //To set page title
    this.titleService.setTitle("Sign up - IBMi Community");
    
    // use FormBuilder to create a form group
    this.signUpform = this.fb.group({
      'firstname': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      'password': ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
    
  }

  //Signup user using email and password
  registerUser(){
    this.userService.registerUser(this.signUpform.value).subscribe(
      data => {
        if(data.req_status == "1"){
          this.showOtpForm = true
          this.signUpForm = false
          this.emailError = false
          this.userEmail = data.useremail;
          this.showMassage = true;
          this.successMassage = "We have sent a verification email to "+ this.userEmail +". Please click on link to verify your email. If you do not receive the verification email, then kindly check spam/junk folder for verification email.";
          setTimeout(() => {
            this.showMassage = false;
            this.router.navigateByUrl('/login');
          }, 3000)

        }else {
          this.emailError = true;
          this.signUpform.setErrors({ 'invalid': true });
        }
      },
      err => {
        this.errors = err;
      }
    );
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'linkedin') {
      socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (Socialusers) => {
        this.savesResponse(Socialusers);
        this.router.navigateByUrl('/');
      },
      err => {
        this.errors = err;
      }
    );
  }

  //Save social login response
  savesResponse(socialusers: Socialusers) {
    this.userService.saveSocialLoginResponse(socialusers).subscribe(
      res => {
        this.socialusers = res;
        this.response = res.userDetail;
        localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
        //this.router.navigate([`/`]); 
      },
      error => {

      });
  }

}
