import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Article, ArticlesService, Category} from '../../core';

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['../../home/home.component.css']
})

export class EditorComponent implements OnInit {
  article: Article = {} as Article;  
  articleForm: FormGroup;
  response=false;
  

  constructor(
    private articlesService: ArticlesService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) { 
    this.articleForm = this.fb.group({
      title: ['', Validators.required],
      description:  ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  
  submitForm() {
    // post the changes
    this.articlesService.save(this.articleForm.value).subscribe(
      data => {
        this.response=true;
        this.articleForm.reset();
        //this.router.navigateByUrl('/');
      },
      err => {
      }
    );
  }

  // Reach text editor settings
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '150px',
      minHeight: '150px',
      maxHeight: '150px',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: '',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['indent','strikeThrough', 'subscript',],
      ['fontSize','insertImage','insertVideo',]
    ]
};

}
