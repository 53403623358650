import { Component, OnInit } from '@angular/core';
import { StarUser,SideContentService } from '../../../core';


@Component({
  selector: 'app-star-of-day',
  templateUrl: './star-of-day.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
export class StarOfDayComponent implements OnInit {
  
  staruser: StarUser={
    AcceptedAnswers:0,
    NoOfAnswers:0,
    author:{
      ID:"",
      Image:"avatar1",
      Name:""
    }
  };
  constructor(private sidecontentservice:SideContentService) {
    this.getStarOfDay();
   }
  ngOnInit(): void {
   
  }

  getStarOfDay(){
    
    this.sidecontentservice.starOfDay()
      .subscribe(data => {   
        this.staruser = data[0];
    });
  }
}