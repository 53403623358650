<div class="container">
  <div class="site-content conten-box">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="d-md-flex d-lg-flex user-content">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
            <li ngbNavItem="top">
              <a ngbNavLink>Profile</a>
              <ng-template ngbNavContent>
                <div class="userprofile-content">
                  <div class="row">
                    <div class="col-12 col-lg-12 d-md-flex d-lg-flex">
                      <div class="user-photo-container">
                        <div class="user-photo">
                          <img width="90px" height="90px" src="../../../api/assets/images/avatars/{{currentUser.Image}}" title="" alt="" />   
                        </div>
                        <button class="btn btn-primary change-btn mt-2" type="button" (click)="fileInput.click()" >Change</button>
                        <input type="file" class="d-none" (change)="onFileSelected($event)" #fileInput>    
                      </div>  
                      <div class="user-info ">
                        <div class="userdetails">
                          <span class="userlabels">Name : </span>
                          <p class="user-name" > {{ currentUser.fullName | titlecase }}</p>
                          <!-- <div class="form-group changeusername">
                            <input type="text" class="form-control" [(ngModel)]="currentName" *ngIf="display">
                            <button class="savename btn btn-primary btn-submit" *ngIf="display"
                              (click)="display = false; displayUser = true; update()">ok</button>
                            <button class="cancelname btn btn-primary btn-cancel ml-2" *ngIf="display"
                              (click)="display = false; displayUser = true">Cancel</button>
                          </div> -->
                        </div>
                        <div class="userdetails">
                          <span class="userlabels">Nickname : </span>
                          <p class="nick-name" *ngIf="displayNickName"> {{ currentUser.displayName | titlecase }}<a
                              (click)="displaynick=true;displayNickName=false;editNickname()" class="edit-btn"><i
                                class="fa fa-pencil"></i></a></p>

                          <div class="form-group changnickname ">
                            <input type="text" class="form-control" [(ngModel)]="currentNickName" *ngIf="displaynick">
                            <button class="savename btn btn-primary btn-submit" *ngIf="displaynick"
                              (click)="displaynick = false; displayNickName = true; update()">ok</button>
                            <button class="cancelname btn btn-primary btn-cancel ml-2" *ngIf="displaynick"
                              (click)="displaynick = false; displayNickName = true">Cancel</button>
                          </div>
                        </div>
                        <div class="userdetails">
                          <span class="userlabels">Email : </span>
                          <p class="usermail">{{ currentUser.email }}</p>
                        </div>
                        <div class="userdetails">
                          <span class="userlabels">Company : </span>
                          <p class="user-name" *ngIf="displayUserCompany"> {{ currentUser.companyName | titlecase }} <a
                              (click)="displayCompany=true;displayUserCompany=false;editCompanyname()" class="edit-btn"><i
                                class="fa fa-pencil"></i></a></p>
                          <div class="form-group changeusername">
                            <input type="text" class="form-control" [(ngModel)]="currentCompanyName" *ngIf="displayCompany">
                            <button class="savename btn btn-primary btn-submit" *ngIf="displayCompany"
                              (click)="displayCompany = false; displayUserCompany = true; update()">ok</button>
                            <button class="cancelname btn btn-primary btn-cancel ml-2" *ngIf="displayCompany"
                              (click)="displayCompany = false; displayUserCompany = true">Cancel</button>
                          </div>
                        </div>

                        <div class="userdetails">
                          <span class="userlabels">Mobile No. : </span>
                          <p class="user-name" *ngIf="displayMobile"> {{ currentUser.mobile | titlecase }} <a
                              (click)="displayMobileNum=true;displayMobile=false;editMobile()" class="edit-btn"><i
                                class="fa fa-pencil"></i></a></p>
                          <div class="form-group changeusername">
                            <input type="tel" maxlength="10" class="form-control" [(ngModel)]="currentMobileNum" *ngIf="displayMobileNum">
                            <button class="savename btn btn-primary btn-submit" *ngIf="displayMobileNum"
                              (click)="displayMobileNum = false; displayMobile = true; update()">ok</button>
                            <button class="cancelname btn btn-primary btn-cancel ml-2" *ngIf="displayMobileNum"
                              (click)="displayMobileNum = false; displayMobile = true">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="middle">
              <a ngbNavLink>Change password</a>
              <ng-template ngbNavContent>
                <div class="row justify-content-center">
                  <div class="col-12 col-md-9 col-lg-7">
                    <div class="forgot-password-content">
                      <h3>Change password</h3>
                      <div class="massages" *ngIf="showMassage">
                        <p class="opt-text alert alert-success"><i class="fa fa-check-circle"></i>Success! Your Password
                          has been changed!</p>
                      </div>
                      <form [formGroup]="changePasswordFrom" (ngSubmit)="changePassword()">
                        <div class="form-label-group">
                          <input type="password" id="currentpassword" class="form-control" placeholder=""
                            formControlName="currentpassword" autocomplete="off" appAutofocus>
                          <label for="firstname">Old password</label>
                          <div
                            *ngIf="changePasswordFrom.get('currentpassword').invalid && (changePasswordFrom.get('currentpassword').dirty || changePasswordFrom.get('currentpassword').touched)"
                            class="alert alert-danger">
                            <div *ngIf="changePasswordFrom.get('currentpassword').errors.required">
                              Password is required.
                            </div>
                          </div>
                          <div class="alert alert-danger" *ngIf="sohwError">
                            Old password doesn't match.
                          </div>
                        </div>
                        <div class="form-label-group">
                          <input type="password" id="newpassword" class="form-control" placeholder=""
                            formControlName="newpassword" autocomplete="off">
                          <label for="firstname">New password</label>
                          <div
                            *ngIf="changePasswordFrom.get('newpassword').invalid && (changePasswordFrom.get('newpassword').dirty || changePasswordFrom.get('newpassword').touched)"
                            class="alert alert-danger">
                            <div *ngIf="changePasswordFrom.get('newpassword').errors.required">
                              Password is required.
                            </div>
                          </div>
                        </div>
                        <div class="form-label-group">
                          <input type="password" id="confirmpassword" class="form-control" placeholder=""
                            formControlName="confirmpassword" autocomplete="off">
                          <label for="firstname">Confirm password</label>
                          <div
                            *ngIf="changePasswordFrom.get('confirmpassword').invalid && (changePasswordFrom.get('confirmpassword').dirty || changePasswordFrom.get('confirmpassword').touched)"
                            class="alert alert-danger">
                            <div *ngIf="changePasswordFrom.get('confirmpassword').errors.required">
                              Password is required.
                            </div>
                            <div *ngIf="changePasswordFrom.get('confirmpassword').errors.mustMatch">Confirm password
                              doesn't match with new password.</div>
                          </div>
                        </div>
                        <button class="btn btn-primary btn-block btn-lg" type="submit"
                          [disabled]="!changePasswordFrom.valid">Change password</button>
                      </form>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>
