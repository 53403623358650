import { Component, OnInit} from '@angular/core';
import { TopCategories,SideContentService,SharedDataService } from '../../../core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
export class CategoriesComponent implements OnInit {

  constructor(private sidecontentservice:SideContentService,private sharedDataService:SharedDataService) { }
  topcategories: TopCategories[];
  ngOnInit(): void {
    this. getTopicCategories();
  }
  

  getTopicCategories(){
    this.topcategories = [];
    this.sidecontentservice.topCategories()
      .subscribe(data => {      
        this.topcategories = data;
    });
  }
  searchCat(searchStr){
    this.sharedDataService.updateCatFilter(searchStr);       
  }
}
