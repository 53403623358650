import { Injectable } from '@angular/core';


import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Article, ArticleListConfig } from '../models';
import { map } from 'rxjs/operators';


@Injectable()

export class ArticlesService {

  constructor(
    private apiService: ApiService
  ) { }

  query() {
    // Convert any filters over to Angular's URLSearchParams
    return this.apiService
      .get('/Topic/getAll');
  }
  queryFilter(filter) {


    return this.apiService.post('/Topic/search', filter)
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  get(topicId): Observable<Article> {
    var tid = {
      topicID: topicId
    }

    return this.apiService.post('/Topic/getByID', tid)
      .pipe(map(
        data => {
          return data;
        },
        err => {
        }
      ));

  }
  increaseView(topicId){
    var tid = {
      topicID: topicId
    }   
    return this.apiService.post('/Topic/addView', tid)
    .subscribe(
        data => {
          return data;
        },
        err => {
        }
      );

  }

  save(articleForm) {
    return this.apiService.post('/Topic/create', articleForm).pipe(map(
      data => {
        return data;
      }
    ));

  }
  getArticleDetails(topicId) {
    var tid = {
      topicID: topicId
    }
    return this.apiService.post('/Topic/getByID', tid).pipe(map(
      data => {
        return data;
      }
    ));

  }
  addTopicvote(topicid, vote, nutralid) {
    let uservote;
    if (vote == nutralid) {
      uservote = 1;

    } else {
      uservote = 0
    }
    const votedata = {
      topicId: topicid,
      nutralId: uservote,
      voteId: vote
    }

    return this.apiService.post('/Topic/topiclikedislike', votedata).pipe(map(
      data => {
        return data;
      }
    ));
  }
  getuservote(topicid) {
    const data = {
      topicID: topicid
    }
    return this.apiService.post('/Topic/getcurrentuservote', data).pipe(map(
      data => {
        return data;
      }
    ));
  }

}
