import { Component } from '@angular/core';

 

@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html',  
  styleUrls: ['./footer.component.css']
})


export class FooterComponent {
  currentYear;
  today = new Date();

  constructor() {
    this.currentYear = this.today.getFullYear();
  }
}
