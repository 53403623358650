import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors, UserService } from '../../core';

import { Socialusers } from '../../core/models/social-user.model';
import { GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider, AuthService } from 'angular-6-social-login';
import { SocialLoginModule, AuthServiceConfig } from 'angular-6-social-login';
import { SocialloginService } from '../../core/services/sociallogin.service';

import { importType } from '@angular/compiler/src/output/output_ast';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '../../core/models/user.model';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {
  authType: String = '';
  title: String = '';
  errors: Errors = { errors: {} };
  isSubmitting = false;
  authForm: FormGroup;
  response
  socialusers = new Socialusers();
  showMassage = false;
  returnUrl: string;
  responseMassage:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private socialAuthService: AuthService,
    private titleService:Title,
    //private socialusers: Socialusers,
    //private socialloginService: SocialloginService

  ) {
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required]
    });

     //To set page title
     this.titleService.setTitle("Log in - IBMi Community");
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }
   

  //Sing in
  submitForm() {
    this.isSubmitting = true;
    this.errors = { errors: {} };
    const credentials = this.authForm.value;

    this.userService.attemptAuth('login', credentials).subscribe(
      data => {
        if(data.req_status == "0"){
          this.showMassage = true;
          this.responseMassage = data.message;
          this.isSubmitting = false;
        }else {
          this.router.navigateByUrl(this.returnUrl);
        }
      },
      err => {
        this.errors = err;
      }
    );
  }

  //Social sign in
  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'linkedin') {
      socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (Socialusers) => {
        this.savesResponse(Socialusers);
        this.router.navigateByUrl('/');
      },
      err => {
        this.errors = err;
      }
    );
  }


  //Save social login response
  savesResponse(socialusers: Socialusers) {
    this.userService.saveSocialLoginResponse(socialusers).subscribe(
      res => {
        this.socialusers = res;
        this.response = res.userDetail;
        localStorage.setItem('socialusers', JSON.stringify(this.socialusers));
      },
      error => {

      });
  }

}
