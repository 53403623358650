import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()

export class SharedDataService {
  private catFilterSubject = new BehaviorSubject("");
  public catFilter = this.catFilterSubject.asObservable();

  private topicFilterSubject = new BehaviorSubject("");
  public topicFilter = this.topicFilterSubject.asObservable();

  private currentPageSubject = new BehaviorSubject(1);
  public currentPage = this.currentPageSubject.asObservable();

  constructor() { }
  updateCatFilter(str){
    this.catFilterSubject.next(str);
  }
  updateTopicFilter(str){
    this.topicFilterSubject.next(str);
  }
  updateCurrentPage(number){
    this.currentPageSubject.next(number);
  }
}
