import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SocialloginService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService

  ) { }

  //Social log in service
  saveSocialLoginResponse(responce){
    return this.apiService.post('/User/savesocialuser', responce).pipe(map(
      data => {
        return data;
      }
    ));
  }
}
