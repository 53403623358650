<header class="headerMain">
  <nav class="navbar navbar-expand-lg navbar-light">
    <section class="container">
      <a class="navbar-brand" routerLink="/">
        <img width="160px" src="../../../assets/images/IFLogo3V5.png"
          alt="IBMi Community Logo">
      </a>
     
      <div class="collapse navbar-collapse" [ngbCollapse]="!isCollapsed" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto" *appShowAuthed="false">
          <li class="nav-item">
            <a class="nav-link" routerLink="/login" (mouseup)="isCollapsed=false">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/register" (mouseup)="isCollapsed=false">Sign up</a>
          </li>
          <li class="nav-item mobileuserLink" >
            <a class="nav-link" href="#staroftheday" (mouseup)="isCollapsed=false">Star of the day</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#topcategories" (mouseup)="isCollapsed=false">Top categories</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#topanswerers" (mouseup)="isCollapsed=false">Top answerers</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#trendingtopics" (mouseup)="isCollapsed=false">Trending topics</a>
          </li>
        </ul>
        
        <ul class="navbar-nav ml-auto" *appShowAuthed="true">
          <li class="nav-item mobileuserLink">
            <span class="user-profile">
              <img src="../../../api/assets/images/avatars/{{currentUser.Image}}">
              </span>
            <a class="nav-link userName"> {{ currentUser.name | titlecase }}</a>
          </li>
          <li class="nav-item" *ngIf="currentUser.userRole=='admin'">
            <a class="nav-link" routerLink="/admin/user" (mouseup)="isCollapsed=false">Manage Users</a>
          </li>
          <li class="nav-item" *ngIf="currentUser.userRole=='admin'">
            <a class="nav-link" routerLink="/admin/question" (mouseup)="isCollapsed=false">Manage Questions</a>
          </li>
          <li class="nav-item" *ngIf="currentUser.userRole=='admin'">
            <a class="nav-link" routerLink="/admin/activity" (mouseup)="isCollapsed=false">Activity Log</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#staroftheday" (mouseup)="isCollapsed=false">Star of the day</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#topcategories" (mouseup)="isCollapsed=false">Top categories</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#topanswerers" (mouseup)="isCollapsed=false">Top answerers</a>
          </li>

          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="#trendingtopics" (mouseup)="isCollapsed=false">Trending topics</a>
          </li>
          <li class="nav-item mobileuserLink">
            <a class="nav-link" href="" (click)="isCollapsed=false;logout()">Log out</a>
          </li>
        </ul>
      </div>
      <div class="headeruser dropdown" *appShowAuthed="true">
        <span class="user-profile">
        <img src="../../../api/assets/images/avatars/{{currentUser.Image}}">
        </span>
        <p class="username" #toggleButton (click)="toggleDisplay()">
          {{ currentUser.fullName | titlecase }}
        </p>
        <div class="dropdown-menu" #menuArea aria-labelledby="navbarDropdownMenuLink" *ngIf="isShowDivIf">
          <a class="dropdown-item" href="" routerLink="/user" >Profile</a>
          <a class="dropdown-item" href="" (click)="isCollapsed=false;logout()">Log out</a>
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="this.isCollapsed = !this.isCollapsed;">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
    </section>
  </nav>
</header>
<div class="overlaye" *ngIf="isCollapsed" (click)="isCollapsed=false;"></div>
