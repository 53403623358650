<div class="left-section-content" id="trendingtopics">
  <div class="left-section-box trending-topics">
    <h3 class="section-title">Trending topics</h3>
    <ul class="topic-list" *ngFor="let i of trendingtopics">
      <li>
        <p><a href="javascript:void(0)" (click)="navQuestDetail(i.TopicID)">{{i.Title}}</a></p>
        <span>{{i.NoOfViews}} Views | {{i.AddedOn}}</span>
      </li>
    </ul>
  </div>
</div>
