 <!--Question box statr here-->
 
        
        <div class="question">
            <div class="question-box">
              <div class="profile-container">
                <div class="profile-box">
                  <img src="api/assets/images/avatars/{{article.author.Image}}" title="" alt="">
                </div>
                <p class="profile-name">{{ article.author.Name }}</p>
              </div>
              <div class="question-container">
                <span class="status-btn open">{{ article.status.Name }}</span>
                <h3 class="question-title">{{article.Title}}</h3>
                <span class="date-info">{{article.AddedOn}} | </span>
                <span class="date-info">Category: {{article.category.Parent}} - {{article.category.Name}} | </span>
                <span class="date-info">{{article.NoOfAnswers}} answers</span>
                <p class="answer-details" [innerHTML]="article.Body"></p>
                <a (click)="navQuestDetail(article.TopicID)" class="view-more">View the topic <i
                    class="fa fa-long-arrow-right"></i></a>
              </div>
            </div>
            <!--<div class="activity-box ">
              <a (click)="doTopicVote(article.TopicID,0)"    class="activities likes" title="Likes">{{article.TotalLikes}}</a>
              <a (click)="doTopicVote(article.TopicID,1)"    class="activities dislikes" title="Dislikes">{{article.TotalDislikes}}</a>
            </div>
          </div>-->
          <!---->
         


 <!--Question box end here-->
