<div class="row">
  <div class="col-12">
    <div class="filter-content">
      <div class="filter-content-top">
        <h3>All Questions</h3>
        <button class="btn askquestion-button" (click)="askQuest()">Ask Question</button>
      </div>
      <div class="filter-content-bottom">
       <!--<p>{{articlesCount}} questions</p>--> 
        <div class="question-sort ml-auto">
          <!-- <button class="btn mobile-sort">Sort</button> -->
          <div class="sort-option" [ngbCollapse]="!filterCollapsed">
            <ul class="nav">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': newestFlag==1}" (click)="toggleNewest()">Newest</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': activeFlag==1}" (click)="toggleActive()">Active</a>
              </li>
              <li class="nav-item">
                <a class="nav-link"[ngClass]="{'active': unansweredFlag==1}" (click)="toggleUnanswered()" >Unanswered</a>
              </li>
              <li class="nav-item" *appShowAuthed="true">
                <a class="nav-link" [ngClass]="{'active': myquestionsFlag==1}" (click)="toggleMyquestions()">My questions</a>
              </li>  
             <!-- <li class="nav-item mobile-filter d-sm-block d-md-none d-lg-none">
                <button class="btn filter-button" (click)="runQueryFilter()">Filter</button>
              </li>       -->            
            </ul>
          </div>
          <!--<button class="btn filter-button d-none d-sm-none d-md-block d-lg-block" (click)="runQueryFilter()">Filter</button>-->
          <button class="btn filter-button d-sm-block d-md-none d-lg-none" (click)="filterCollapsed = !filterCollapsed"><i class="fa fa-filter"></i></button>
        </div>
      </div>
      <!--Filter dropdown Start here-->
      <div class="fillter-dropdown">
        <div class="filter-dropdown-content">
          <div class="row">
            <div class="col-md-4 col-12">
              <h4>Filter by</h4>
              <ul class="option-list">
                <li><input type="checkbox" name="filter-by" alt="">No answers</li>
                <li><input type="checkbox" name="filter-by" alt="">No accepted
                  answers</li>
              </ul>
            </div>
            <div class="col-md-4 col-12">
              <h4>Sorted by</h4>
              <ul class="option-list">
                <li><input type="checkbox" name="filter-by" alt="">No answers</li>
                <li><input type="checkbox" name="filter-by" alt="">No accepted
                  answers</li>
              </ul>
            </div>
            <div class="col-md-4 col-12">
              <h4>Tagged with</h4>
              <ul class="option-list">
                <li><input type="text" name="" title="" class="form-control"></li>
                <li><input type="checkbox" name="filter-by" alt="">No answers</li>
                <li><input type="checkbox" name="filter-by" alt="">No accepted
                  answers</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn apply-button">Apply Filter</button>
            </div>
          </div>
        </div>
      </div>
      <!--Filter dropdown End here-->
    </div>
  </div>
</div>

<div class="row">
    <div class="col-12">
    <app-article-preview 
        *ngFor="let article of results"
        [article]="article" (onLikedislike)="doTopicVote($event)">
    </app-article-preview>    
    </div>
</div>
<div class="row">
    <div class="col-12">
<h6 *ngIf="articlesCount==0" class="p-2">No result</h6>
<nav class="pagination-content" *ngIf="articlesCount!=0">
  <ul class="pagination">
    <li class="page-item pagination-control" *ngIf="currentPage!=1" (click)="pageChangeArrow(-1)"><span class="page-link"><i
            class="fa fa-angle-double-left"></i></span>
    </li>
    <li class="page-item"
      [ngClass]="{'active': pageNumber === currentPage}"
      *ngFor="let pageNumber of totalPages"
      (click)="setPageTo(pageNumber)">

      <a class="page-link page-number" >{{ pageNumber }}</a>

    </li>
    <li class="page-item pagination-control" *ngIf="currentPage!=this.totalPages.length" (click)="pageChangeArrow(+1)"><a class="page-link" href="#"><i
            class="fa fa-angle-double-right"></i></a>
    </li>

  </ul>
</nav>
</div>
</div>

