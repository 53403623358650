import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService,
  ) { }

  getQuestions() {
    return this.apiService.get("/Topic/getAll")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }
  getActivtyLog() {
    return this.apiService.get('/Admin/getactivitylog');
  }
  acceptQuestions(postQue) {
    return this.apiService.post("/Topic/update", postQue)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }
  

  getUsers() {
    return this.apiService.get("/User/getAll")
      .pipe(map(
      data => {
        return data;
      }
    ));
  }

  acceptUsers(postUser) {
    return this.apiService.post("/User/update", postUser)
      .pipe(map(
      data => {
        return data;
      }
    ));
  }
}
