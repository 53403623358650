import { Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../core';
import { ArticleListComponent } from '../shared/article-helpers/article-list/article-list.component';
import { CategoriesComponent } from '../shared/side-content/categories/categories.component'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  
})
export class HomeComponent implements OnInit {
  @ViewChild(ArticleListComponent ) articleLestComp: ArticleListComponent ; 

  display=false;
  isAuthenticated: boolean;  
  searchForm: FormGroup;
  constructor(
    private router: Router,
    private userService: UserService,    
    private fb: FormBuilder,
  ) {
    this.searchForm = this.fb.group({
      'searchBox': ['', Validators.required],
      'searchType': ["0", Validators.required]
    });
  }

  ngOnInit(): void {
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;

      }
    );
  }
  askQuestP(){
    if (!this.isAuthenticated) {
      this.router.navigateByUrl('/login');
      return;
    }else{
      this.display=true;
    }

    
  }
  
  submitForm() {
   this.articleLestComp.SearchQuery(this.searchForm.value);
  }

}
