import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordEmailComponent } from './change-password-email/change-password-email.component';

const routes: Routes = [
  {
    path: 'login',
    component: SigninComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'register',
    component: SignupComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'verify/:username/:otp',
    component: VerifyOtpComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ChangePasswordEmailComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'changepassword/:resetflag',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})
export class AuthRoutingModule {}
