import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { AuthServiceConfig } from "angular-6-social-login";
import { getAuthServiceConfigs } from './config/sociallogin.config'
import { SocialLoginModule } from "angular-6-social-login";
import { AngularMaterialModule } from "./angular-material.module"

import {
  ApiService,
  JwtService,
  UserService,
  CommentsService,
  ArticlesService,
  AdminService,SideContentService,SharedDataService,
  AuthGuard
} from './services';
import { from } from 'rxjs';
import { AutofocusDirective } from './directives/autofocus.directive';


@NgModule({
  imports: [
    CommonModule,
    SocialLoginModule,
    AngularMaterialModule,   
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    JwtService,
    UserService,
    ArticlesService,
    CommentsService,
    AdminService,
    SideContentService,
    SharedDataService,
    AuthGuard,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }
  ],

  declarations: [
    AutofocusDirective
  ],

  exports: [
    AngularMaterialModule,
    AutofocusDirective
  ]
})
export class CoreModule { }
