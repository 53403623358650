import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { map ,  distinctUntilChanged } from 'rxjs/operators';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,) { }
    forgotPassword(passint){  
      return this.apiService.post('/User/forgotpassword', passint).pipe(map(
      data => {
        return data;
      }
    ));
  }

    
}
