<div class="container forgot-email">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-6">
        <div class="forgot-password-content" *ngIf="showMassage">
          <h3>Reset your password?</h3>
          <p>We have sent a reset password email to <span class="useremail-text">{{ userEmail.email }}</span>. Please click the rest password link to set your new password.</p>
          <p>Didn’t receive the email yet?</p><p>
            Please check your spam folder, or <a (click)="resendRequest()" routerLink="/forgot-password">resend</a> the email.</p>
        </div>
        <div class="forgot-password-content" *ngIf="showForm">
          <h3>Forgot your password?</h3>
          <p>Don’t worry! Just fill in your email and we’ll send you a link to reset your password.</p>
          <form [formGroup]="forgotEmailForm" (ngSubmit)="onPasswordChangeRequest()">
            <div class="form-label-group">
              <input type="email" id="email" class="form-control" placeholder="" formControlName="email"
                autocomplete="off" appAutofocus>
              <label for="firstname">Email</label>
              <div
                *ngIf="forgotEmailForm.get('email').invalid && (forgotEmailForm.get('email').dirty || forgotEmailForm.get('email').touched)"
                class="alert alert-danger">
                <div *ngIf="forgotEmailForm.get('email').errors.required">
                  Email is required.
                </div>
                <div *ngIf="forgotEmailForm.get('email').errors.email">Email must be a valid email address</div>
              </div>
              <div class="alert alert-danger" *ngIf="showError">
                Email address doesn't exist
              </div>
            </div>
            <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="!forgotEmailForm.valid">Reset password</button>
          </form>
        </div>
      </div>
    </div>
</div>
