import { Component, OnInit, HostListener, ElementRef,Renderer2, ViewChild} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../core/services/user.service';
import { User } from '../../core/models/user.model';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',  
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menuArea') menuArea: ElementRef;
 
  isShowDivIf = false;
  currentUser: User;
  dropdown = false;
  public isCollapsed = false;
  
  constructor(
    private userService : UserService,
    private router : Router,
    private _el: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click',(e:Event)=>{  
      if(e.target !== this.toggleButton.nativeElement){
              this.isShowDivIf=false;
          }
      });
    
  }

  //to open dropdown
  toggleDisplay() {
    this.isShowDivIf = !this.isShowDivIf;
  }


  //Top open mobile menu
  toggleMenu(event){
    this.isCollapsed = !this.isCollapsed;
  }

  

  ngOnInit() {
    this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        console.log(this.currentUser)
      }
    );
  }
  
  logout() {
    this.userService.purgeAuth();
    this.router.navigateByUrl('/');
  }
  
}
