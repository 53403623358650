import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map ,  distinctUntilChanged } from 'rxjs/operators';


@Injectable()
export class UserService {

  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();


  constructor (
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) {

  }


  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('/User/getUserByToken')
      .subscribe(            
        data => {this.setAuth(data)},
        err => this.purgeAuth()
      );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    this.jwtService.saveToken(user.token);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  attemptAuth(type, credentials): Observable<User> {
    const route = (type === 'login') ? '/login' : '';
    return this.apiService.post('/User/signin', credentials)
      .pipe(map(
      data => { 
          if(data.req_status == "1"){
            this.setAuth(data.user);
          }
          return data;
      }
    ));
  }
   

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
    .put('/user', { user })
    .pipe(map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }

  //Signup user new user
  registerUser(user){
    return this.apiService.post('/User/signup', user).pipe(map(
      data => {
        return data;
      }
    ));
  }

  //Signup - OTP verification
  otpVerification(username, Otp){
    return this.apiService.post('/User/verifyotp', {username, Otp}).pipe(map(
      data => {
        return data;
      }
    ));
  }

  //Signup user - Resnd OTP verification
  otpResend(email){
    return this.apiService.post('/User/resendotp', {email}).pipe(map(
      data => {
        return data;
      }
    ));
  }

    //Social log in service
    saveSocialLoginResponse(responce){
      return this.apiService.post('/User/savesocialuser', responce).pipe(map(
        data => {
          this.setAuth(data.user);
          return data;
        }
      ));
    }

  //Chansge password email
  changePasswordEmail(email){
    return this.apiService.post('/User/forgotpassword', email).pipe(map(
      data => {
        return data;
      }
    ));
  }

  //Reset password
  resetPassword(password, resetflag){
    return this.apiService.post('/User/resetpassword', {password,resetflag}).pipe(map(
      data => {
        return data;
      }
    ));
  }

   //Change password
   changePassword(changePasswordValue){
    return this.apiService.post('/User/changepassword', changePasswordValue ).pipe(map(
      data => {
        return data;
      }
    ));
  }
  // userupdate
  userUpdate(currentNickName,currentCompanyName,currentMobileNum){
  
    let obj = {DisplayName:currentNickName,CompanyName:currentCompanyName,Mobile:currentMobileNum};
    console.log(obj)
      return this.apiService.post('/User/update', obj ).pipe(map(
        data => {
          return data;
        }
      ));
  }

  postSelectedFile(data:File) {  
    const uploadData = new FormData();
    uploadData.append('avatar', data);
    let params = new HttpParams();
    const options={
      params:params
    }
    //return this.apiService.post('/User/updateAvatar',uploadData)
    return this.http.post(environment.api_url+'/User/updateAvatar',uploadData,options)
  }
}
