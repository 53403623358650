<!-- OPT from start -->
<!-- <div class="validateOpt">
  <form [formGroup]="otpFrom" (ngSubmit)="veryifyOtp()">
    <div *ngIf="showMassage">
      <p class="opt-text alert alert-success"><i class="fa fa-check-circle"></i> {{successMassage}}</p>
    </div>
    <div class="form-label-group">
      <input type="text" id="Otp" class="form-control" placeholder="Enter OTP" formControlName="Otp">
      <label for="Otp">Enter OTP</label>
    </div>
    <div *ngIf="invalideOtp" class="alert alert-danger mb-2">
      <div>Invalide Otp</div>
    </div>
    <div *ngIf="optError" class="alert alert-danger mb-2">
      <div>OTP has Expired </div>
    </div>
    <div class="checkbox">
      <p class="opt-text" *ngIf="!optError">Time remaining <span>{{timeLeft}}</span></p>
      <p *ngIf="optError" class="opt-text">OTP has Expired <a href="javascript: void(0)" (click)="resendOtp()">Resend OTP</a></p>
    </div>
    <button class="btn btn-lg btn-warning btn-block" type="submit" [disabled]="!otpFrom.valid">Validate OTP</button>
  </form>
</div> -->
<section class="main-content">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5 col-lg-5 col-12">
        <div class="veryify-content" *ngIf="showMassage">
          <h2>Your account is verified!</h2>
          <span class="veryify-icon">
            <i class="fa fa-check"></i>
          </span>
          <p>We have successfully verified your account.</p>
          <p><a routerLink="/" class="btn btn-primary continue-btn">Continue</a></p>
        </div>
      </div>
    </div>
  </div>
</section>
