import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { Errors, UserService, StarUser } from '../../core';
import { flatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password-email',
  templateUrl: './change-password-email.component.html',
  styleUrls: ['./change-password-email.component.css']
})
export class ChangePasswordEmailComponent implements OnInit {
  errors: Errors = {errors: {}};
  forgotEmailForm : FormGroup;
  userEmail;
  showMassage = false;
  showError = false;
  showForm = true;
  responseMassage: string;

  constructor(
    private fb : FormBuilder,
    private userService : UserService,
    private router: Router,
    private titleService : Title,
  ) { 

    //To set page title
    this.titleService.setTitle("Forgot password - IBMi Community");

    this.forgotEmailForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
    });

  }

  ngOnInit(): void {
    this.userEmail = this.forgotEmailForm.value;
  }

  //Change password after enter user email address
  onPasswordChangeRequest(){
    this.userEmail = this.forgotEmailForm.value;
    this.userService.changePasswordEmail(this.userEmail).subscribe(
      data => {
        if(data.req_status == "0"){
          this.showError = true;
          this.responseMassage = data.message;
        }else {
          this.showForm = false;
          this.showMassage = true;
            // setTimeout(() => {
            //   this.router.navigateByUrl('/login');
            // }, 10000)
          }
      },
      error =>{
        this.errors = error;
      })
  }

  resendRequest(){
    this.showMassage = false;
    this.forgotEmailForm.reset();
    this.showError = false;
    this.showForm = true;
  }

}
