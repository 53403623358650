import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../core/angular-material.module';

import { AuthRoutingModule } from './auth-routing.module';
import { NoAuthGuard } from './no-auth-guard.service';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';

import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { SharedModule } from '../shared';
import { CoreModule } from '../core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordEmailComponent } from './change-password-email/change-password-email.component';

@NgModule({
  declarations: [ 
    SigninComponent, 
    SignupComponent, 
    VerifyOtpComponent, ForgotPasswordComponent, ChangePasswordEmailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    AuthRoutingModule,
    AngularMaterialModule,
  ],
  providers:[
    NoAuthGuard
  ]
})
export class AuthModule { }
