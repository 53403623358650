

<div class="ask-question">
    <h2 class="page-title">Ask your Question</h2>
    <div class="model-content">
      <form class="form" [formGroup]="articleForm" (ngSubmit)="submitForm()">
          <div class="form-group">
            <label for="questionTitle">Title<span class="asterisk">*</span></label>
            <input type="text" formControlName="title" class="form-control" id="questionTitle" placeholder="" appAutofocus>
            <div *ngIf="articleForm.get('title').invalid && (articleForm.get('title').dirty || articleForm.get('title').touched)"
                  class="alert alert-danger">
                  <div *ngIf="articleForm.get('title').errors.required">
                    Title is required.
                  </div>
                </div>
          </div>
          <div class="form-group">
            <label for="questionDetails">Details<span class="asterisk">*</span></label>
            <angular-editor class="reach-text" formControlName="description" [config]="editorConfig"></angular-editor>
            <div *ngIf="articleForm.get('description').invalid && (articleForm.get('description').dirty || articleForm.get('description').touched)"
                  class="alert alert-danger">
                  <div *ngIf="articleForm.get('description').errors.required">
                    Description is required.
                  </div>
                </div>
          </div>
          <button type="submit" class="btn btn-primary submit-question" [disabled]="!articleForm.valid">Submit</button>
        </form>
        <div class="response-text" *ngIf="response">Your question submitted successfully and is waiting for our administrator approval. Please check back later.</div>
    </div>
  </div>
