import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotpasswordService } from '../../core/services/forgotpassword.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors, UserService } from '../../core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  errors: Errors = {errors: {}};
  changePasswordFrom : FormGroup;
  errorText:string;
  showMassage = false;
  responseMassage:string;
  resetflag;
  password;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.resetflag = this.route.snapshot.params.resetflag;;
    });
  }

  constructor(
    private fb : FormBuilder,
    private fpservices:ForgotpasswordService,
    private router: Router,
    private route : ActivatedRoute,
    private userservice : UserService,
    private titleService : Title,
  ) { 

    //To set page title
    this.titleService.setTitle("Reset password - IBMi Community");

    this.changePasswordFrom = this.fb.group({
      'newPassword': ['', Validators.required],
      'confirmPassword': ['', Validators.required],
      }, {
        validator: this.MustMatch('newPassword', 'confirmPassword')
    })

  }

  //For checking new password and confirm password validation
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }

  changePassword(){
    this.password = this.changePasswordFrom.controls["confirmPassword"].value;
    this.userservice.resetPassword(this.password, this.resetflag).subscribe(
      data => {
        this.showMassage = true;
          setTimeout(() => {
            this.showMassage = false;
            this.router.navigateByUrl('/login');
          }, 3000)
      },
      error => {
        this.errors = error;
      }
    )
  }

}
