import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors, UserService, User } from '../../core';
import { filter, map } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";


import { Socialusers } from '../../core/models/social-user.model';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { SocialloginService } from '../../core/services/sociallogin.service';


@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
  @Input() userEmail : string;

  errors: Errors = {errors: {}};
  showError= true;
  showMassage = false;
  username; 
  otp;
  color = '#c51e38';
  mode = 'determinate';
  value = 50;
  
    
  constructor(
    private formotp : FormBuilder,
    private userService: UserService,
    private router: Router,
    private route : ActivatedRoute,
    private titleService:Title,
  ) {
    
    //To set page title
    this.titleService.setTitle("User is verified - IBMi Community");
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.username = this.route.snapshot.params.username;
      this.otp = this.route.snapshot.params.otp;
      this.veryifyUser(this.username, this.otp);
    });
  }
  
  //OTP verification after signup
  veryifyUser(username, otp){
    this.userService.otpVerification(username, otp).subscribe(
      data => {
        if(data.req_status == "0"){
          this.showMassage = false;
          this.showError = true;
        }else if(data.req_status == "2"){
          this.showMassage = false;
          this.showError = true;
        }else {
          this.showError = false;
          this.showMassage = true;
        }
      },
      err => {
        this.errors = err;
      })
  }

}
