import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Article, Filter, ArticleListConfig, ArticlesService,SharedDataService } from '../../../core';
import { Router,ActivatedRoute , RouterState } from '@angular/router';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
export class ArticleListComponent implements OnInit {
  @Output("askQuestP") askQuestP: EventEmitter<any> = new EventEmitter();

  public filterCollapsed = false;

  constructor(
    private articlesService: ArticlesService,
    private sharedDataService:SharedDataService,
    private titleService:Title,  
    private route: ActivatedRoute,  
    private router: Router,

  ) {
    //To set page title
    this.titleService.setTitle("All questions - IBMi Community");
   }

  newestFlag = 1;
  activeFlag = 0;
  unansweredFlag = 0;
  myquestionsFlag = 0;
  results: Article[];
  totalPages: Array<number> = [3];
  currentPage = 1;
  filter: Filter;
  limit = 10;
  searchForm = {
    searchBox: "",
    searchType: 0
  };
  articlesCount: number;
  uservote: any;
  catFilter:string;
  topicFilter:string;
  pageNumberHistory:number;

  ngOnInit(): void {   
    
    this.sharedDataService.catFilter.subscribe(
      (catFilter) => {
        this.catFilter = catFilter;
        this.categoryFilter();
      }
    );
    this.sharedDataService.topicFilter.subscribe(
      (topicFilter) => {
        this.topicFilter = topicFilter;
        this.topicFilterFunc();       
      }
    );
    this.sharedDataService.currentPage.subscribe(
      (currentPage) => {
        console.log(currentPage);
        this.currentPage = currentPage;
        this.runQueryFilter();   
      }
    );
   // this.currentPage=window.sessionStorage['currentPage'];
    //this.setPageTo(this.currentPage);
   // console.log(window.sessionStorage['currentPage']);     
    
  }
  setPageTo(pageNumber) {    
    //this.currentPage = pageNumber;
    this.sharedDataService.updateCurrentPage(pageNumber);   
    //this.runQueryFilter();
  }
  pageChangeArrow(val) {
    this.currentPage = this.currentPage + val;
    this.runQueryFilter();
  }

  runQuery() {
    this.results = [];
    this.articlesService.query()
      .subscribe(data => {
        this.results = data;
      });
  }
  runQueryFilter() {
    this.filter = {
      "searchKey": this.searchForm.searchBox,
      "searchType": this.searchForm.searchType,
      "pageStart": (this.limit * (this.currentPage - 1)),
      "pageLimit": this.limit,
      "filterNewest": this.newestFlag,
      "filterActive": this.activeFlag,
      "filterMyQuestions": this.myquestionsFlag,
      "filterUnanswered": this.unansweredFlag
    }
    this.results = [];
    this.articlesService.queryFilter(this.filter)
      .subscribe(data => {
        this.results = data.articles;
        console.log(this.results);
        this.articlesCount = data.NoOfArticles;
        this.totalPages = Array.from(new Array(Math.ceil(data.NoOfArticles / this.limit)), (val, index) => index + 1);
        window.sessionStorage['currentPage'] = this.currentPage;
      });
  }
  categoryFilter(){   
    this.searchForm.searchBox=this.catFilter;
    this.searchForm.searchType=1;
    this.currentPage = 1;    
    this.runQueryFilter();
   // this.setPageTo(1);
    //this.runQueryFilter();

  }
  topicFilterFunc(){
    this.searchForm.searchBox=this.topicFilter;
    this.searchForm.searchType=0;
    this.currentPage = 1;    
    this.runQueryFilter();
    //this.runQueryFilter();
  }
  SearchQuery(searchForm) {
    this.searchForm = searchForm;
    this.currentPage = 1;    
    this.runQueryFilter();
    //this.runQueryFilter();
  }
  askQuest() {
    this.askQuestP.emit();
  }
  toggleNewest() {
    this.newestFlag = this.newestFlag == 1 ? 0 : 1;
    this.setPageTo(1);
    //this.runQueryFilter();
  }
  toggleActive() {
    this.activeFlag = this.activeFlag == 1 ? 0 : 1;
    this.setPageTo(1);
    //this.runQueryFilter();
  }
  toggleUnanswered() {
    this.unansweredFlag = this.unansweredFlag == 1 ? 0 : 1;
    this.setPageTo(1);
   // this.runQueryFilter();
  }
  toggleMyquestions() {
    this.myquestionsFlag = this.myquestionsFlag == 1 ? 0 : 1;
    this.setPageTo(1);
    //this.runQueryFilter();
  }
  public doTopicVote(data) {

    this.articlesService.getuservote(data[0]).subscribe(
      result => {
        this.uservote = result;
        if (this.uservote == data[1]) {
          this.uservote = 1;
        } else {
          this.uservote = 0;

        }
        this.articlesService
          .addTopicvote(data[0], data[1], this.uservote)
          .subscribe(
            topic => {
              if (topic.req_status == 1) {
                this.updateResults(data[0], data[1], this.uservote);
              }
            },
            errors => {
            }
          );

      },
      errors => {
      }
    );

  }
  updateResults(topicid, vote, uservote) {
    this.results.map((data) => {
      if (data.TopicID == topicid) {
        if (uservote == 1) {
          if (vote == 0) {
            const votes = +data.TotalLikes;
            data.TotalLikes = votes - 1;
          } else {
            const votes = +data.TotalDislikes;
            data.TotalDislikes = votes - 1;
          }
        } else {
          if (vote == 0) {
            const votes = +data.TotalLikes;
            data.TotalLikes = votes + 1;
          } else {
            const votes = +data.TotalDislikes;
            data.TotalDislikes = votes + 1;
          }
        }
      }
    });
  }

}
