import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ArticleListComponent } from './article-helpers/article-list/article-list.component';
import { ArticlePreviewComponent } from './article-helpers/article-preview/article-preview.component';
import { ArticleMetaComponent } from './article-helpers/article-meta/article-meta.component';
import { EditorComponent } from './editor/editor.component';
import { TopAnswersComponent } from './side-content/top-answers/top-answers.component';
import { TredingTopicsComponent } from './side-content/treding-topics/treding-topics.component';
import { CategoriesComponent } from './side-content/categories/categories.component';
import { StarOfDayComponent } from './side-content/star-of-day/star-of-day.component';
import { ShowAuthedDirective } from './show-authed.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../core';
import { FbLikeComponent } from './article-share/fb-share/fb-share.component';
import { LinkedInShareComponent } from './article-share/linkdin-share/linkdin-share.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AngularEditorModule,
    NgbModule,
    CoreModule
  ],
  declarations: [ArticleListComponent,ArticlePreviewComponent, ArticleMetaComponent,EditorComponent, 
    TopAnswersComponent, TredingTopicsComponent, CategoriesComponent, StarOfDayComponent, ShowAuthedDirective, FbLikeComponent, LinkedInShareComponent],
  exports: [   
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ArticleListComponent,
    ArticlePreviewComponent,
    EditorComponent,
    TredingTopicsComponent,CategoriesComponent,StarOfDayComponent,TopAnswersComponent,
    ShowAuthedDirective,FbLikeComponent,LinkedInShareComponent
  ]
})
export class SharedModule {}
