<div class="container forgot-email">
    <div class="forgot-password">
      <div class="row justify-content-center">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="alert alert-success success-massage" *ngIf="showMassage">
            <i class="fa fa-check-circle"></i> Change password Success. Your Password has been changed!
          </div>
          <div class="forgot-password-content">
           <p>Enter your new password to reset your account.</p>
            <form [formGroup]="changePasswordFrom" (ngSubmit)="changePassword()">
              <div class="form-label-group">
                <input type="password" id="newPassword" class="form-control" placeholder="" formControlName="newPassword"
                  autocomplete="off" appAutofocus>
                <label for="firstname">New password</label>
                <div
                  *ngIf="changePasswordFrom.get('newPassword').invalid && (changePasswordFrom.get('newPassword').dirty || changePasswordFrom.get('newPassword').touched)"
                  class="alert alert-danger">
                  <div *ngIf="changePasswordFrom.get('newPassword').errors.required">
                      Password is required.
                  </div>
                </div>
              </div>
              <div class="form-label-group">
                  <input type="password" id="confirmPassword" class="form-control" placeholder="" formControlName="confirmPassword"
                    autocomplete="off">
                  <label for="firstname">Confirm password</label>
                  <div
                    *ngIf="changePasswordFrom.get('confirmPassword').invalid && (changePasswordFrom.get('confirmPassword').dirty || changePasswordFrom.get('confirmPassword').touched)"
                    class="alert alert-danger">
                    <div *ngIf="changePasswordFrom.get('confirmPassword').errors.required">
                        Password is required.
                    </div>
                    <div *ngIf="changePasswordFrom.get('confirmPassword').errors.mustMatch">Passwords must match.</div>
                  </div>
                </div>
                <button class="btn btn-primary btn-block btn-lg" type="submit" [disabled]="!changePasswordFrom.valid">Reset password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  