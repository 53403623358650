import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { Comment } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class CommentsService {

  constructor(
    private apiService: ApiService
  ) { }

  approvecomment(topicid,commentId) {
    var cData = {
      topicid: topicid,
      answerid: commentId
    }
    return this.apiService.post('/Topic_comment/setAcceptedAnswer', cData).pipe(map(
      data => {
        return data;
      }
    ));
  }
  add(topicid, payload,commentId) {
    var cData = {
      topicId: topicid,
      text: payload,
      parentCommentId: commentId
    }
    return this.apiService.post('/Topic_comment/addcomment', cData).pipe(map(
      data => {
        return data;
      }
    ));
  }

  getAll(topicid) {

    var tid = {
      'topicid': topicid
    }
    return this.apiService.post('/Topic_comment/gettopiccomments', tid).pipe(map(
      data => {
        return data;
      }
    ));
  }
  addcommentvote(data) {
    const votedata = {
      commentId: data.CommentID,
      nutralId: data.nutralId,
      voteId: data.currentvote
    }
    return this.apiService.post('/Topic_comment/commentlikedislike', votedata).pipe(map(
      returndata => {
        return returndata;
      }
    ));
  }

  destroy(commentId, articleSlug) {
    return this.apiService
      .delete(`/articles/${articleSlug}/comments/${commentId}`);
  }
}
