import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute , RouterState } from '@angular/router';
import { TrendingTopics,SideContentService , SharedDataService,UserService} from '../../../core';

@Component({
  selector: 'app-treding-topics',
  templateUrl: './treding-topics.component.html',
  styleUrls: ['./../../../home/home.component.css']
})
export class TredingTopicsComponent implements OnInit {

  constructor(     
    private route: ActivatedRoute,
    private router: Router,
    private sidecontentservice:SideContentService,
    private sharedDataService:SharedDataService,    
    private userService: UserService
  ){ }

  trendingtopics: TrendingTopics[];
  isAuthenticated: boolean;
  ngOnInit(): void {
    this.getTrendingTopic();
    this.userService.isAuthenticated.subscribe(
      (authenticated) => {
        this.isAuthenticated = authenticated;
      }
    );
  }

  getTrendingTopic(){
    this.trendingtopics = [];
    this.sidecontentservice.trendingTopic()
      .subscribe(data => {      
        this.trendingtopics = data;
    });
  }
  searchTopic(searchStr){
    this.sharedDataService.updateTopicFilter(searchStr);    
  }
  navQuestDetail(topicId){  
    
   
    if (!this.isAuthenticated) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: '/article/'+topicId }});
      return;
    }else{
      this.router.navigate(['/article', topicId]);
    }

  }
}
